import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import elizabeth from "../../../images/about/elizabethcox.jpg";
import {
  AboutImage,
  AboutImageContainer,
  AboutImageText,
  AboutTable,
  AboutTableData,
  AboutTableRow,
} from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const ElizabethCox = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/elizabeth-cox");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Elizabeth Cox | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Elizabeth Cox, CPA, CFP®, is a founding partner of Merit Financial Partners, LLC (MFP). She is deeply committed to helping clients every step of the way to achieve strength and independence through life transitions. She provides clients with a broad range of financial services including personal financial planning, divorce financial analysis, investment advisory and management, and retirement and tax planning. Elizabeth is trained as a financial specialist in mediation and the collaborative divorce process."
          }
        />
      </Helmet>
      <p>
        Elizabeth Cox, CPA, CFP®, is a founding partner of Merit Financial
        Partners, LLC (MFP). She is deeply committed to helping clients every
        step of the way to achieve strength and independence through life
        transitions. She provides clients with a broad range of financial
        services including personal financial planning, divorce financial
        analysis, investment advisory and management, and retirement and tax
        planning. Elizabeth is trained as a financial specialist in mediation
        and the collaborative divorce process.
      </p>
      <p>
        Elizabeth has more than 30&nbsp;years of financial and investment
        experience including 7 years as President of Cox Financial Services,
        LLC, 4 years as an independent financial advisor with Raymond James
        Financial Services and 12 years of international bond and equity trading
        on Wall Street. She was formerly director of International Sales Trading
        at Deutsche Bank Securities and head of the Latin American Sales Trading
        Desk at ING Baring Securities in New York.
      </p>
      <p>
        Elizabeth is a member of the CFP Board’s Public Policy Council which
        serves an advisory role on public policy issues affecting&nbsp;CFP®
        professionals and members of the public.
      </p>
      <AboutTable>
        <AboutTableRow>
          <AboutTableData>
            <strong>
              Elizabeth’s professional and academic qualifications include:
            </strong>
            <ul>
              <li>Certified Public Accountant certification (CPA)</li>
              <li>CERTIFIED FINANCIAL PLANNER® certification</li>
              <li>
                MIA, International Finance (full merit scholarship), Columbia
                University, New York, New York
              </li>
              <li>A (cum laude), Middlebury College, Middlebury, Vermont</li>
            </ul>
            <p>
              <strong>
                Elizabeth is a member of the following organizations:
              </strong>
            </p>
            <ul>
              <li>
                <a href="http://www.aicpa.org/Pages/default.aspx">
                  American Institute of Certified Public Accountants (AICPA)
                </a>
              </li>
            </ul>
          </AboutTableData>
          <AboutTableData>
            <AboutImageContainer>
              <AboutImage src={elizabeth} />
              <AboutImageText>
                <h4>Elizabeth Cox</h4>
                CPA, CFP®
                <br />
                <a href="mailto:ecox@meritfp.com">Email Me</a>
              </AboutImageText>
            </AboutImageContainer>
          </AboutTableData>
        </AboutTableRow>
      </AboutTable>
    </PageContainerDivContent>
  );
};
