import { PageContainerDivContent } from "../../../components/PageContainer/styles";
import melissa from "../../../images/about/melissaadams.jpg";
import {
  AboutImage,
  AboutImageContainer,
  AboutImageText,
  AboutTable,
  AboutTableData,
  AboutTableRow,
} from "../styles";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { TRACKING_ID } from "../../../App";

export const MelissaAdams = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview("/melissa-adams");
  }, []);
  return (
    <PageContainerDivContent>
      <Helmet>
        <title>Melissa Adams | Merit Financial Partners</title>
        <meta
          name={"description"}
          content={
            "Melissa Adams is a CERTIFIED FINANCIAL PLANNER®, Chartered Life Underwriter and Registered Investment Advisor with Merit Financial Partners, LLC (MFP), a fee only financial planning and Registered Investment Advisory firm. She strives to build long-term, trusting relationships with clients by bringing them peace of mind as she guides them through wealth management and retirement planning strategies."
          }
        />
      </Helmet>
      <p>
        Melissa Adams is a CERTIFIED FINANCIAL PLANNER®, Chartered Life
        Underwriter and Registered Investment Advisor with Merit Financial
        Partners, LLC (MFP), a fee only financial planning and Registered
        Investment Advisory firm. She strives to build long-term, trusting
        relationships with clients by bringing them peace of mind as she guides
        them through wealth management and retirement planning strategies. She
        specializes in helping clients navigate through the transitions in their
        lives – investment planning, college savings, divorce, the loss of a
        spouse, and preparing for and living in retirement.
      </p>
      <p>
        Melissa has over 22 years experience in financial services industry with
        leadership roles at Manulife Financial, John Hancock and Prudential in
        the areas of retirement sales management and broker/dealer and
        investment firm relationship management. Most recently, Melissa was the
        Senior Relationship Manager at Prudential Annuities where she was
        responsible for managing over 40 asset manager relationships
        representing over 170 billion in assets.
      </p>
      <AboutTable>
        <AboutTableRow>
          <AboutTableData>
            <strong>
              Melissa’s professional and academic qualifications include:
            </strong>
            <ul>
              <li>CERTIFIED FINANCIAL PLANNER® certification</li>
              <li>CHARTERED LIFE UNDERWRITER® (CLU)</li>
              <li>Registered Investment Advisor Agent</li>
              <li>
                Bachelors of Art, Economics; Minors, Urban Studies and Law
                Society, University of California, San Diego, CA
              </li>
              <li>
                Retirement Strategy Program, University of Pennsylvania, The
                Wharton School, Pennsylvania, PA
              </li>
              <li>Licenses: Series 7, 63, 24, Life, Accident and Health</li>
            </ul>
          </AboutTableData>
          <AboutTableData>
            <AboutImageContainer>
              <AboutImage src={melissa} />
              <AboutImageText>
                <br />
                <h4>Melissa Adams</h4>
                CFP®
                <br />
                <a href="mailto:madams@meritfp.com">Email Me</a>
              </AboutImageText>
            </AboutImageContainer>
          </AboutTableData>
        </AboutTableRow>
      </AboutTable>
    </PageContainerDivContent>
  );
};
