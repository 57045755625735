import {
  H1,
  MainContentTable,
  MainContentTableContainer,
  MainContentTableData,
  MainContentTableRow,
} from "./styles";
import { CallToAction } from "./components/CallToAction/CallToAction";
import styled from "styled-components";

export const Home = () => {
  return (
    <div>
      {/*<Banner />*/}
      <CallToAction />
      <MainContentTableContainer>
        <MainContentTable>
          <MainContentTableRow>
            <MainContentTableData>
              <H1>{"Welcome".toUpperCase()}</H1>
              <hr />
              <br />
              <b>
                If you are seeking objective, competent financial advice you can
                trust, you have come to the right place. Whether you seek:
              </b>
              <br />
              <br />
              <ul>
                <li>Wealth Management / Investment Management</li>
                <li>Financial Advice: hourly or project-based</li>
                <li>Retirement Planning</li>
                <li>Divorce Financial Planning and Analysis</li>
              </ul>
              Now you have an advocate. Someone who is on YOUR side and who
              takes the time to understand what YOU need. We are fiduciaries who
              strive to understand your financial goals, needs and concerns so
              that we can give you advice tailored just for you.
              <br />
              <br />
              {/*<H7>We Merit Your Trust!</H7>*/}
            </MainContentTableData>

            <MainContentTableData>
              <H1>{"Who We Are".toUpperCase()}</H1>
              <hr />
              <br />
              Founded in 2006, Merit Financial Partners, LLC is an independent
              Fee-Only financial planning and Registered Investment Advisory
              firm with offices in New York City and Westport CT. We are
              Certified Financial Planners and fiduciaries who specialize in
              providing investment management and financial planning services to
              individuals and families. As independent Fee-Only advisors, we
              work solely for our clients. Because no commissions are ever
              received, potential conflicts of interest regarding compensation
              are removed. Clients have the flexibility to engage us on either a
              one-time, as-needed or ongoing basis.
              <H1>{"How We Work".toUpperCase()}</H1>
              <hr />
              <br />
              First, we talk. We get to know each other and lay out a foundation
              to move forward. We will ask you what your goals are, your
              attitudes about money, your life stage, financial obligations, and
              more. After your initial consultation, we will recommend a
              suitable plan or customized package to help you to reach your
              goals.
              <br />
              <br />
              You may choose to engage our firm to implement investment
              strategies recommended in your financial plan. We pride ourselves
              on providing you with personal, reliable ongoing advice on your
              investments and financial planning concerns. We are committed to a
              fiduciary standard; we are bound to place clients’ interests above
              that of the firm.
              <br />
              <br />
              <Link href={"/contact"}>Contact us</Link> today to see how easy it
              is to get started.
              <br />
              <br />
            </MainContentTableData>
          </MainContentTableRow>
        </MainContentTable>
      </MainContentTableContainer>
    </div>
  );
};

const Link = styled.a`
  color: var(--MERIT-BLUE-DARKEST);
  text-decoration: none;

  &:hover {
    color: var(--MERIT-BLUE-DARK);
    text-decoration: underline;
  }
`;
