import { FooterContainer, FooterHeaderContainer } from "./styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterHeaderContainer>
        <p>
          "Plan for your future because that's where you're going to spend the
          rest of your life."
        </p>
        <p style={{ fontSize: "14px" }}>- Mark Twain</p>
      </FooterHeaderContainer>
      <hr />
      All written content on this site is for information purposes only. Merit
      Financial Partners, LLC is a Registered Investment Advisor providing
      fee-only investment advice and guidance, and full-service financial
      planning. The information in this web site is designed to provide a
      general overview of the subject matter covered and is not state specific.
      The authors, publishers, and host are not providing legal, accounting, or
      tax advice, or advice specific to your situation. Material presented is
      believed to be from reliable sources and we make no representations as to
      its accuracy or completeness.
      <br />
      <i>
        Certified Financial Planner Board of Standards Center for Financial
        Planning, Inc. owns and licenses the certification marks CFP®,
        CERTIFIED FINANCIAL PLANNER®, and CFP® (with plaque design) in the
        United States to Certified Financial Planner Board of Standards, Inc.,
        which authorizes individuals who successfully complete the
        organization’s initial and ongoing certification requirements to use the
        certification marks.
      </i>
    </FooterContainer>
  );
};
